@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.confirm {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;

  transition: opacity 1s ease-out;
}

.confirm[data-fadeout="true"] {
  opacity: 0;
}

.titleWrap {
  padding: 16px;
}

.title {
  font-family: "Playfair Display";
  font-size: 60px;
  line-height: 160%;
  color: #4402ff;
  text-align: center;

  margin: 0;
  margin-bottom: 8px;

  text-shadow: 0px 0px 8px rgba(68, 2, 255, 0.3);
}

.titleJa {
  font-family: "Zen Old Mincho";
  font-size: 24px;
  line-height: 160%;
  color: #4402ff;
  text-align: center;

  margin: 0;
  margin-bottom: 40px;

  text-shadow: 0px 0px 8px rgba(68, 2, 255, 0.3);
}

.titleJa span {
  display: inline-block;
  white-space: nowrap;
}

@media screen and (max-width: 720px) {
  .title {
    font-size: 40px;
  }
}

/* Yes / No */

.bottomArea {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
}

.box {
  display: flex;
  align-items: baseline;

  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
}

.ask {
  font-size: 14px;
  padding: 8px 0;
  margin: 0 8px;
}

button.no {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  cursor: pointer;

  border: none;
  background: none;

  padding: 0;
  margin: 0;

  line-height: 1.2;
  padding: 8px;
}

button.ok {
  font-size: 14px;
  font-weight: 700;
  color: #4402ff;
  cursor: pointer;

  border: none;
  background: none;

  padding: 0;
  margin: 0;

  line-height: 1.2;
  padding: 8px;
}

.no:hover,
.ok:hover {
  opacity: 0.5;
}
