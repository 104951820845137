.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
}

.player {
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);

  z-index: 101;
}

@media screen and (max-width: 1200px) {
  .player {
    width: 560px;
    height: 315px;
  }
}

@media screen and (max-width: 600px) {
  .player {
    width: 280px;
    height: 158px;
  }
}
