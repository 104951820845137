.sidemenu {
  width: 0;
  flex-shrink: 0;
  flex-grow: 0;

  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  background-color: #fff;
  opacity: 0;
  transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
}

.sidemenu[data-open="true"] {
  width: 240px;
  opacity: 1;
  transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
}

.sidemenuInner {
  width: 240px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.overlay {
  display: block;
  visibility: hidden;
  transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
}

@media screen and (max-width: 720px) {
  .sidemenu {
    display: none;
    width: 240px;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 101;
  }

  .sidemenu[data-open="true"] {
    display: initial;
  }

  .overlay[data-open="true"] {
    visibility: visible;

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;

    background-color: rgba(68, 2, 255, 0.8);

    transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.iconWrap {
  display: flex;
}

.iconClose {
  padding: 8px;
  fill: #4402ff;
  margin: 24px 8px 8px auto;
  cursor: pointer;
}

.iconClose:hover {
  background-color: #fafafa;
}

.sidemenuHeaderWrap {
  padding: 16px;
  margin-bottom: 8px;
}

.sidemenuHeader {
  margin: 0;
  font-size: 16px;
  line-height: 1.4;

  border-bottom: 1px solid #000;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.sidemenuSubheader {
  margin: 0;
  font-size: 10px;
  line-height: 1.4;
}

.trackItemRoot {
  padding: 0 24px;
}

.trackItemRoot:hover {
  background-color: #fafafa;
}

.trackItem {
  display: flex;
  padding: 13px 0;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #e0e0e0;
}

.trackName {
  font-size: 14px;
  line-height: 24px;
}

.iconPlay {
  fill: #d0d0d0;
  margin-left: auto;
}

.iconPlay[data-active="true"] {
  fill: #4402ff;
}

.toTop {
  margin: 36px 0;
  text-align: center;
}

.toTopLink {
  color: #333;
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  text-decoration: none;
}

@media (hover: hover) {
  .toTopLink:hover {
    color: #4402ff;
  }
}

.copyright {
  font-size: 10px;
  line-height: 1.6;
  color: #bdbdbd;
  text-align: center;

  margin: 16px 0;
}
