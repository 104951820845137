.confirm {
  text-align: center;
  padding: 80px 0;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lamp {
  max-width: 120px;
  margin-bottom: 24px;
}

.note {
  text-align: center;
  max-width: 600px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 40px;

  font-size: 16px;
  line-height: 1.8;
}

.note span {
  display: inline-block;
  white-space: nowrap;
}

.box {
  display: inline-flex;
  align-items: baseline;

  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
}

.ask {
  font-size: 14px;
  padding: 8px 0;
  margin: 0 8px;
}

.link {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  text-decoration: none;

  line-height: 1.2;
  padding: 8px;
}

button.ok {
  font-size: 14px;
  font-weight: 700;
  color: #4402ff;
  cursor: pointer;

  border: none;
  background: none;

  padding: 0;
  margin: 0;

  line-height: 1.2;
  padding: 8px;
}

.link:hover,
.ok:hover {
  opacity: 0.5;
}
