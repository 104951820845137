.root {
  position: fixed;
  top: 100%;
  bottom: 0;
  right: 0;
  left: 0;

  overflow-x: hidden;
  overflow-y: scroll;
  transition: top 0.25s ease-out;

  z-index: 1000;

  background-color: #fff;
  opacity: 0;
  transition: 0.7s cubic-bezier(0.22, 1, 0.36, 1);
}

.root[data-open="true"] {
  top: 0;
  opacity: 1;
  transition: 1s cubic-bezier(0.22, 1, 0.36, 1);
}

.buttonClose {
  position: sticky;
  top: 16px;
  right: 16px;

  margin-left: auto;
  margin-bottom: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 8px;

  font-size: 12px;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);

  color: #000;
  fill: #000;

  cursor: pointer;
}

.buttonClose:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.section {
  padding: 0 32px;
  margin-bottom: 72px;
}

.sectionCredit {
  border-top: 1px solid #e0e0e0;
  padding: 120px 32px;
  margin-top: 80px;
}

.title {
  font-family: "Allison", cursive;
  font-weight: normal;
  text-align: center;
  font-size: 60px;
  line-height: 120%;
  color: #4402ff;
  text-shadow: 0px 0px 8px rgba(68, 2, 255, 0.3);

  margin: 0;
}

.titleJa {
  text-align: center;
  font-size: 16px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  line-height: 160%;
  color: #4402ff;
  text-shadow: 0px 0px 8px rgba(68, 2, 255, 0.2);

  margin-bottom: 24px;
}

.text {
  font-size: 16px;
  line-height: 180%;
  text-align: center;
}

.imageSenario {
  max-width: 328px;
  width: 100%;
  display: block;
  margin: 0 auto;
  filter: drop-shadow(8px 8px 0px rgba(0, 0, 0, 0.1));
}

.textGray {
  font-size: 18px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  line-height: 1.8;
  text-align: center;
  color: #828282;
}

.linkButton {
  display: block;
  margin: 24px auto;
  text-decoration: none;
  width: 160px;
  line-height: 38px;

  text-align: center;
  font-size: 14px;
  font-weight: 700;

  border: 1px solid #000;
  color: #000;
  transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
}

@media (hover: hover) {
  .linkButton:hover {
    background-color: #000;
    color: #fff;
    transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.original {
  text-align: center;
  font-size: 18px;
  line-height: 1.8;
}

.table {
  max-width: 560px;
  margin: 0 auto;
  margin-top: 48px;
}

.tableHeader {
  display: flex;
  border-bottom: 1px solid #000;
}

.tableTitle {
  font-size: 18px;
  line-height: 1.8;
}

.tableTitle span {
  white-space: nowrap;
}

.tableHeaderLink {
  display: flex;
  align-items: center;

  margin-left: auto;
  font-size: 18px;
  line-height: 1.8;
  color: #4402ff;
  text-decoration: none;

  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}

.tableHeaderLink svg {
  fill: #4402ff;
  margin-left: 4px;
}

.small {
  font-size: 15px;
}

@media (hover: hover) {
  .tableHeaderLink:hover {
    opacity: 0.5;
    transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.tableRow {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 8px 0;
}

.tableRow:last-child {
  border-bottom: none;
}

.tableCol {
  font-size: 14px;
  line-height: 1.8;
}

.tableCol:first-child {
  width: 240px;
}

.copy {
  text-align: center;
  color: #bdbdbd;
  margin-bottom: 72px;
}

.nowrap {
  display: inline-block;
  white-space: nowrap;
}

.link {
  color: #2f80ed;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}

@media (hover: hover) {
  .link:hover {
    opacity: 0.5;
    transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  }
}

@media screen and (max-width: 720px) {
  .original {
    margin-bottom: 18px;
  }

  .tableHeader {
    font-size: 16px;
    flex-direction: column;
  }

  .tableRow {
    flex-direction: column;
  }

  .tableCol:first-child {
    width: auto;
    font-size: 12px;
  }

  .title {
    line-height: 80%;
  }

  .titleJa {
    margin-top: 20px;
  }
}
