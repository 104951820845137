.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 40px;

  z-index: 100;
  display: flex;
  align-items: center;
}

.headerMain {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.headerTitleArea {
  margin: 8px 0;
}

.headerTitle {
  font-size: 24px;
  line-height: 1.6;
  margin: 0;
}

.headerTitleLink {
  color: inherit;
  text-decoration: none;
}

.headerSubtitle {
  font-size: 10px;
  line-height: 1.6;
}

.headerMenu {
  display: flex;
}

.headerMenuItem {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6;
  margin-right: 24px;

  display: flex;
  align-items: center;
  vertical-align: baseline;

  cursor: pointer;
  color: #000;
  text-decoration: none;

  user-select: none;

  transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
}

.headerMenuItem svg {
  height: 16px;
  width: 16px;
  margin-left: 4px;
  fill: #000;
}

@media (hover: hover) {
  .headerMenuItem:hover {
    color: #4402ff;
  }

  .headerMenuItem:hover svg {
    fill: #4402ff;
  }
}

.headerMenuButton {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 8px;
  cursor: pointer;
}

.headerMenuButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.headerMenuButton[data-hidden="true"] {
  display: none;
}

@media screen and (max-width: 720px) {
  .header {
    padding: 16px 24px;
  }

  .headerMenu {
    flex-direction: column;
    margin-top: 16px;
  }

  .headerMenuItem {
    font-size: 18px;
    line-height: 2;
  }

  .header {
    align-items: unset;
  }

  .headerMenuButton {
    margin-top: 8px;
  }
}

.wrap {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.mainarea {
  flex-grow: 1;
  flex-shrink: 1;
  width: 1%;
  position: relative;
}

.body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(200px);
}

@supports (-webkit-touch-callout: none) {
  .body {
    height: -webkit-fill-available;
    height: 100dvh;
  }
}

.main {
  width: 100%;
  flex-shrink: 1;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
    transform: translateY(200px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000;
}

.item span {
  color: #000;
  opacity: 0.5;
  cursor: pointer;
  user-select: none;
}

.item[data-active="true"] span {
  opacity: 1;
}

.item span:hover {
  opacity: 1;
}

.playList {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}
