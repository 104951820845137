.backgroundVideo {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: -1;
}

.backgroundVideo::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 1.5s ease-out;
}

.backgroundVideo[data-blur="true"]::after {
  opacity: 1;
}

.video {
  min-width: 110%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
