.audioPlayer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 40px;
  left: calc(50% - 140px);

  width: 280px;
  height: 60px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  transition: left 0.3s cubic-bezier(0.22, 1, 0.36, 1);

  margin: 0 auto;
}

.audioPlayer[data-shift="true"] {
  left: calc(50% - 140px - 120px);
}

@media screen and (max-width: 720px) {
  .audioPlayer {
    bottom: 20px;
  }

  .audioPlayer[data-shift="true"] {
    left: calc(50% - 140px);
  }
}

.audioPlayer[data-hidden="true"] {
  visibility: hidden;
}

.audioButton {
  fill: #333;
  cursor: pointer;
  user-select: none;
  padding: 2px;
}

.audioButton:hover {
  background-color: #f0f0f0;
}

.audioButton[data-active="true"] {
  fill: #4402ff;
}

.audioButton[data-active="false"] {
  fill: #d0d0d0;
}

.disk {
  position: absolute;
  bottom: 24px;
  left: 16px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 33px solid #000;
  box-sizing: border-box;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  animation: rotate 10s linear infinite;
  animation-play-state: paused;
  user-select: none;
}

.disk[data-playing="true"] {
  animation-play-state: running;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.audioButtons {
  display: flex;
  column-gap: 4px;
  margin: 0 14px;
}
