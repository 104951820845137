.track {
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  height: 100vh;
  height: 100dvh;
  min-height: 500px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  padding-bottom: 80px;
  box-sizing: border-box;
}

@supports (-webkit-touch-callout: none) {
  .track {
    height: -webkit-fill-available;
    height: 100dvh;
  }
}

.jacketWrap {
  position: relative;
  width: 100%;
}

.jacket {
  display: block;
  max-width: 60%;
  width: 400px;
  aspect-ratio: 1/1;
  margin: 0 auto;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.musicShape {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(50% + 120px);
}

.musicShape h2 {
  font-family: "Playfair Display";
  font-size: 60px;
  font-weight: normal;
  line-height: 1.2;
  color: #4402ff;
  text-shadow: 0px 0px 8px rgba(67, 67, 67, 0.2);

  margin: 0;
}

.musicShape div {
  font-size: 24px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  line-height: 1.6;
  color: #4402ff;
  text-shadow: 0px 0px 8px rgba(68, 2, 255, 0.5);

  margin: 0;
}

.readMore {
  width: 160px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #4402ff;
  margin-top: 16px;
  padding: 0 32px;
  color: #fff;
  background-color: #4402ff;

  transition: 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  transition-property: color, background-color;

  cursor: pointer;
}

.readMoreSP {
  display: none;
  width: 160px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #4402ff;
  margin-top: 16px;
  padding: 0 32px;
  color: #fff;
  background-color: #4402ff;

  transition: 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  transition-property: color, background-color;

  cursor: pointer;
}

@media (hover: hover) {
  .readMore:hover,
  .readMoreSP:hover {
    color: #4402ff;
    background-color: #fff;
  }
}

@media screen and (max-width: 960px) {
  .musicShape {
    left: 50%;
  }

  .musicShape h2 {
    font-size: 56px;
  }

  .musicShape div {
    font-size: 18px;
  }
}

@media screen and (max-width: 720px) {
  .track {
    padding: 0;
  }

  .musicShape h2 {
    font-size: 40px;
  }

  .musicShape div {
    font-size: 16px;
  }

  .readMore {
    display: none;
  }

  .readMoreSP {
    display: unset;
  }
}

@media screen and (max-width: 460px) {
  .musicShape h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 320px) {
  .musicShape h2 {
    font-size: 24px;
  }

  .musicShape div {
    font-size: 12px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  z-index: 2001;
  opacity: 0;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  animation: fadein 0.6s;
  animation-fill-mode: forwards;

  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  box-sizing: border-box;
}

.modal::-webkit-scrollbar {
  display: none;
}

.modal[data-closing="true"] {
  animation: fadeout 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0%,
  80% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.buttonClose {
  position: fixed;
  top: 16px;
  right: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 8px;

  font-size: 12px;
  border: none;
  background-color: transparent;

  color: #000;
  fill: #000;

  cursor: pointer;
}

.buttonClose:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.buttonClose[data-color="white"] {
  color: #fff;
  fill: #fff;
}

.buttonClose:hover[data-color="white"] {
  background-color: rgba(255, 255, 255, 0.1);
}

.modalContent {
  color: #000;

  font-family: "Inter";

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  padding: 80px 0;
  box-sizing: border-box;
}

.modalContent[data-color="white"] {
  color: #fff;
}

.trackBody {
  max-width: 320px;
}

.trackName {
  font-family: "Playfair Display";
  font-size: 60px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
}

.trackLead {
  font-size: 24px;
  line-height: 1.6;
  margin-bottom: 24px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
}

.trackText {
  font-size: 16px;
  font-family: -apple-system, "Helvetica", "Noto Sans JP", sans-serif;
  line-height: 2;
  white-space: pre;
}

.voteButton {
  display: inline-flex;
  align-items: center;

  line-height: 24px;
  font-size: 14px;
  font-family: -apple-system, "Helvetica", "Noto Sans JP", sans-serif;
  font-weight: 600;
  border: 1px solid #000;
  margin-top: 16px;
  padding: 12px 24px;
  color: #000;
  background-color: transparent;
  text-decoration: none;

  user-select: none;

  transition: 0.2s cubic-bezier(0.22, 1, 0.36, 1);

  cursor: pointer;
}

.voteButton svg {
  margin-right: 4px;
  fill: #000;
  transition: fill 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}

.voteButton[data-color="white"] {
  border-color: #fff;
  color: #fff;
}

.voteButton[data-color="white"] svg {
  fill: #fff;
}

@media (hover: hover) {
  .voteButton:hover {
    color: #fff;
    background-color: #333;
  }

  .voteButton:hover svg {
    fill: #fff;
  }

  .voteButton:hover[data-color="white"] {
    color: #333;
    background-color: #fff;
  }

  .voteButton:hover[data-color="white"] svg {
    fill: #333;
  }
}

.cdTransitionLayer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.cdTransitionLayer .bgLayer {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-2%);
  -moz-transform: translateY(-50%) translateX(-2%);
  -ms-transform: translateY(-50%) translateX(-2%);
  -o-transform: translateY(-50%) translateX(-2%);
  transform: translateY(-50%) translateX(-2%);
  /* its dimentions will be overwritten using jQuery to proportionally fit the viewport */
  height: 100%;
  /* our sprite is composed of 25 frames */
  width: 2500%;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
}
.cdTransitionLayer[data-visible="true"] {
  opacity: 1;
  visibility: visible;
}
.cdTransitionLayer[data-open="true"] .bgLayer {
  -webkit-animation: cd-sequence 0.8s steps(24);
  -moz-animation: cd-sequence 0.8s steps(24);
  animation: cd-sequence 0.8s steps(24);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.cdTransitionLayer[data-close="true"] .bgLayer {
  -webkit-animation: cd-sequence-reverse 0.8s steps(24);
  -moz-animation: cd-sequence-reverse 0.8s steps(24);
  animation: cd-sequence-reverse 0.8s steps(24);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes cd-sequence {
  0% {
    /* translateX(-2%) is used to horizontally center the first frame inside the viewport */
    -webkit-transform: translateY(-50%) translateX(-2%);
  }
  100% {
    /* translateX(-98%) (2% + 96) is used to horizontally center the last frame inside the viewport  */
    -webkit-transform: translateY(-50%) translateX(-98%);
  }
}
@-moz-keyframes cd-sequence {
  0% {
    /* translateX(-2%) is used to horizontally center the first frame inside the viewport */
    -moz-transform: translateY(-50%) translateX(-2%);
  }
  100% {
    /* translateX(-98%) (2% + 96) is used to horizontally center the last frame inside the viewport  */
    -moz-transform: translateY(-50%) translateX(-98%);
  }
}
@keyframes cd-sequence {
  0% {
    /* translateX(-2%) is used to horizontally center the first frame inside the viewport */
    -webkit-transform: translateY(-50%) translateX(-2%);
    -moz-transform: translateY(-50%) translateX(-2%);
    -ms-transform: translateY(-50%) translateX(-2%);
    -o-transform: translateY(-50%) translateX(-2%);
    transform: translateY(-50%) translateX(-2%);
  }
  100% {
    /* translateX(-98%) (2% + 96) is used to horizontally center the last frame inside the viewport  */
    -webkit-transform: translateY(-50%) translateX(-98%);
    -moz-transform: translateY(-50%) translateX(-98%);
    -ms-transform: translateY(-50%) translateX(-98%);
    -o-transform: translateY(-50%) translateX(-98%);
    transform: translateY(-50%) translateX(-98%);
  }
}

@-webkit-keyframes cd-sequence-reverse {
  0% {
    -webkit-transform: translateY(-50%) translateX(-98%);
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(-2%);
  }
}
@-moz-keyframes cd-sequence-reverse {
  0% {
    -moz-transform: translateY(-50%) translateX(-98%);
  }
  100% {
    -moz-transform: translateY(-50%) translateX(-2%);
  }
}
@keyframes cd-sequence-reverse {
  0% {
    -webkit-transform: translateY(-50%) translateX(-98%);
    -moz-transform: translateY(-50%) translateX(-98%);
    -ms-transform: translateY(-50%) translateX(-98%);
    -o-transform: translateY(-50%) translateX(-98%);
    transform: translateY(-50%) translateX(-98%);
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(-2%);
    -moz-transform: translateY(-50%) translateX(-2%);
    -ms-transform: translateY(-50%) translateX(-2%);
    -o-transform: translateY(-50%) translateX(-2%);
    transform: translateY(-50%) translateX(-2%);
  }
}

.bgLayerLast {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  height: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  z-index: 2002;
}

.cdTransitionLayer[data-open="true"] .bgLayerLast {
  animation: cd-show 0.5s steps(24);
  animation-fill-mode: forwards;
}

@keyframes cd-show {
  0%,
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cdTransitionLayer[data-close="true"] .bgLayerLast {
  display: none;
}
